.sidebar
  flex-shrink: 0
  position: relative
  width: 304px
  height: 100%
  margin-right: -304px
  background: $invert
  border-left: 1px solid $border
  border-top-right-radius: 8px
  transition: right .05s linear, margin-right $tr-options

  #{$dark-theme}
    background: #2d3a4b
    border-color: #939b9f

  @media (max-width: 768px)
    position: absolute
    right: 0

  &_active
    margin-right: 0

  &__content
    position: sticky
    top: 0
    display: flex
    flex-direction: column
    min-height: calc(100vh - 48px)

    form
      display: flex
      flex-direction: column
      flex-grow: 1

  &__header
    padding: 16px
    border-bottom: 1px solid $border

    #{$dark-theme}
      border-color: #939b9f

    & > .core-title_h2
      &:first-of-type
        padding-right: 32px

  &__body
    flex-grow: 1
    max-height: calc(100svh - 168px)
    padding: 16px

  &__footer
    padding: 16px
    border-top: 1px solid $border

    #{$dark-theme}
      border-color: #939b9f

  &__wave
    position: absolute
    bottom: -48px

    &_1
      left: 0
      width: 78.59px
      height: 29.23px
      background: url('#{$path}/img/wave_1.svg') no-repeat left bottom / contain

    &_2
      right: 0
      width: 168.28px
      height: 24.42px
      background: url('#{$path}/img/wave_2.svg') no-repeat right bottom / contain

    &_3
      right: 0
      width: 111.89px
      height: 47.95px
      background: url('#{$path}/img/wave_3.svg') no-repeat right bottom / contain
