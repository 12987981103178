.page
  position: relative
  display: flex
  width: 100%
  min-width: 0
  background: $invert
  border-top-right-radius: 8px
  overflow: clip

  #{$dark-theme}
    background: #2d3a4b
