.recharts
  &-tooltip
    padding: 16px
    background: $invert
    box-shadow: $shadow
    border-radius: 2px

    #{$dark-theme}
      background: linear-gradient(180deg, #505759, #364b52)

  &-treemap-depth-1
    transition: opacity $tr-options
    cursor: pointer

    &:hover
      @media (hover)
        opacity: .8
