.header

  &__logo
    width: 108px
    height: 16px
    background: url('#{$path}/img/logo.svg') no-repeat center / contain

    @media #{$mobile}
      width: 26px
      background-image: url('#{$path}/img/logo_min.svg')

  &__wrapper
    display: flex
    justify-content: space-between
    align-items: center
    gap: 24px
    max-width: 1920px
    margin: 0 auto
    padding: 8px 16px

    &_nav
      padding-left: 4px
