.core-box
  &:nth-child(-n+3)
    .core-context-menu__popup
      @media (min-width: 769px)
        max-height: calc(100vh - 64px)

  &:nth-child(1)
    .core-context-menu__popup
      @media #{$tablet}
        max-height: calc(100vh - 64px)
