.scroll
  overflow: auto

  @supports (overflow: overlay)
    overflow: overlay

  @supports not selector(::-webkit-scrollbar)
    scrollbar-width: thin
    scrollbar-color: $main transparent

  &_macos
    scrollbar-color: $main transparent

  &:not(&_macos)
    &::-webkit
      &-scrollbar
        width: 7px
        height: 7px

        &-thumb
          background-color: $main
          border-radius: 3.5px
