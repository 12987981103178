*
  margin: 0
  padding: 0
  box-sizing: border-box
  font-family: Roboto, helvetica, arial, sans-serif
  border: none
  outline: none
  text-decoration: none

html, body, #root
  height: 100%

body
  &::before
    content: ''
    position: fixed
    top: 0
    left: 0
    width: 100vw
    height: 100vh
    background: url('#{$path}/img/bg.jpg') no-repeat center / cover
    z-index: -1

a
  display: inline-block
  flex-grow: 0
