.code
  position: relative
  display: flex
  flex-direction: column
  padding: 20px 0
  background: $bg
  border-radius: 2px

  #{$dark-theme}
    background: #556165

  &__group
    display: flex
    flex-direction: column

  &__row
    padding: 4px 24px
    white-space: pre

  span
    color: $text-main

    #{$dark-theme}
      color: #d9d9d9

  &__mark
    position: absolute
    left: 8px
    user-select: none

  .code
    padding: 0

    &__row
      padding-left: 16px

  span
    font-family: monospace
