@font-face
	font-family: 'Roboto'
	font-style: normal
	font-weight: 300
	src: local('Roboto'), url('#{$path}/fonts/roboto-v27-latin_cyrillic-300.woff2') format('woff2'), url('#{$path}/fonts/roboto-v27-latin_cyrillic-300.woff') format('woff')
	font-display: swap

@font-face
	font-family: 'Roboto'
	font-style: italic
	font-weight: 300
	src: local('Roboto'), url('#{$path}/fonts/roboto-v27-latin_cyrillic-300italic.woff2') format('woff2'), url('#{$path}/fonts/roboto-v27-latin_cyrillic-300italic.woff') format('woff')
	font-display: swap

@font-face
	font-family: 'Roboto'
	font-style: normal
	font-weight: 400
	src: local('Roboto'), url('#{$path}/fonts/roboto-v27-latin_cyrillic-regular.woff2') format('woff2'), url('#{$path}/fonts/roboto-v27-latin_cyrillic-regular.woff') format('woff')
	font-display: swap

@font-face
	font-family: 'Roboto'
	font-style: italic
	font-weight: 400
	src: local('Roboto'), url('#{$path}/fonts/roboto-v27-latin_cyrillic-italic.woff2') format('woff2'), url('#{$path}/fonts/roboto-v27-latin_cyrillic-italic.woff') format('woff')
	font-display: swap

@font-face
	font-family: 'Roboto'
	font-style: normal
	font-weight: 500
	src: local('Roboto'), url('#{$path}/fonts/roboto-v27-latin_cyrillic-500.woff2') format('woff2'), url('#{$path}/fonts/roboto-v27-latin_cyrillic-500.woff') format('woff')
	font-display: swap

@font-face
	font-family: 'Roboto'
	font-style: italic
	font-weight: 500
	src: local('Roboto'), url('#{$path}/fonts/roboto-v27-latin_cyrillic-500italic.woff2') format('woff2'), url('#{$path}/fonts/roboto-v27-latin_cyrillic-500italic.woff') format('woff')
	font-display: swap

@font-face
	font-family: 'Roboto'
	font-style: normal
	font-weight: 700
	src: local('Roboto'), url('#{$path}/fonts/roboto-v27-latin_cyrillic-700.woff2') format('woff2'), url('#{$path}/fonts/roboto-v27-latin_cyrillic-700.woff') format('woff')
	font-display: swap

@font-face
	font-family: 'Roboto'
	font-style: italic
	font-weight: 700
	src: local('Roboto'), url('#{$path}/fonts/roboto-v27-latin_cyrillic-700italic.woff2') format('woff2'), url('#{$path}/fonts/roboto-v27-latin_cyrillic-700italic.woff') format('woff')
	font-display: swap
