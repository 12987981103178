.home
  padding: 0 16px 128px

  &__wrapper
    display: flex
    flex-direction: column
    gap: 1px
    width: 100%
    max-width: 888px
    margin: 0 auto

  &__item
    padding: 16px 8px
    background: $invert
    border-radius: 4px

    #{$dark-theme}
      background: #2d3a4b

  &__link
    @media (hover)
      &:hover
        .icon
          #{$any-theme}
            fill: $main

        .core-title
          color: $main

    .icon
      #{$light-theme}
        fill: $text-main

    .core-title
      transition: color $tr-options
