.legend
  position: relative
  display: flex
  width: 248px
  border-left: 6px solid
  border-radius: 2px
  transition: opacity $tr-options
  overflow: hidden
  cursor: default
  user-select: none

  &_inactive
    opacity: .5
    cursor: pointer

    &:hover
      @media (hover)
        opacity: 1

  &__bg
    position: absolute
    top: 0
    height: 100%
    opacity: .1

  &__wrapper
    display: flex
    justify-content: space-between
    width: 100%
    padding: 4px 8px
    z-index: 0
