.app
  display: flex
  flex-direction: column
  height: 100%

  &_alt-table-view
    .core-table__row
      &:first-of-type
        border-top: 1px solid $border

        #{$dark-theme}
          border-color: #67777e

      &:nth-of-type(2n + 2)
        background: $bg

        #{$dark-theme}
          background: #374c52

    .core-table__cell
      &:not(:last-of-type)
        border-right: 1px solid $border

        #{$dark-theme}
          border-color: #67777e
