.nav
  flex-shrink: 0
  width: 40px
  background: linear-gradient(180deg, rgba(255, 255, 255, .15) 0%, rgba(255, 255, 255, .1) 100%)
  border-top-left-radius: 8px
  transition: width $tr-options

  @media (hover)
    &:hover
      width: 220px

      @media #{$tablet}
        width: 100%

  &_active
    width: 220px

    @media #{$tablet}
      width: 100%

      .nav
        &__content
          padding-right: 4px

        &__item
          border-radius: 16px

          & > .nav__ears
            @media #{$tablet}
              visibility: hidden
              opacity: 0

  &__content
    position: sticky
    top: 0
    display: flex
    flex-direction: column
    height: calc(100vh - 48px)
    gap: 16px
    padding: 9.5px 0px 9.5px 4px
    transition: padding-right $tr-options
    overflow-x: hidden !important

  &__item
    position: relative
    display: flex
    align-items: center
    gap: 8px
    padding-right: 4px
    border-radius: 16px 0 0 16px
    transition: background $tr-options, border-radius $tr-options
    cursor: pointer
    user-select: none

    @mixin active
      background: $invert

      #{$dark-theme}
        background: #2d3a4b

      & > .nav__ears
        visibility: visible
        fill: $invert
        opacity: 1

        #{$dark-theme}
          fill: #2d3a4b

      & > .icon
        #{$light-theme}
          fill: $text-main

      & > .core-text
        #{$light-theme}
          color: $text-main

    & > .icon
      fill: $invert

    & > .core-text
      transition: color $tr-options

    @media (hover)
      &:hover
        +active

    &_active
      +active

  &__ears
    visibility: hidden
    position: absolute
    right: 0
    opacity: 0
    transition: visibility $tr-options, opacity $tr-options
