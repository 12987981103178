.icon
  flex-shrink: 0
  width: 32px
  height: 32px
  padding: 6px
  fill: $main
  transition: fill $tr-options

  #{$dark-theme}
    fill: $invert
