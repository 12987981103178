.account
  position: relative

  @mixin active
    & > .account__button
      background: rgba(0, 0, 0, .3)

  &_active
    .account__trigger
      +active

    .account__menu
      visibility: visible
      opacity: 1

  &__trigger
    display: flex
    align-items: center
    gap: 8px
    cursor: pointer

    @media (hover)
      &:hover
        +active

    & > .core-text
      user-select: none

  &__button
    display: flex
    background: none
    border-radius: 50%
    transition: background $tr-options

    & > .core-icon
      fill: $invert

  &__menu
    position: absolute
    top: 40px
    right: 0
    visibility: hidden
    padding: 16px
    background: $invert
    border-radius: 4px
    box-shadow: $shadow
    opacity: 0
    z-index: 1
    transition: visibility $tr-options, opacity $tr-options
    cursor: initial

    #{$dark-theme}
      background: linear-gradient(180deg, #505759, #364b52)
